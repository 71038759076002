<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Varaukset</div>

    <v-card class="mt-2">
      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Hae kaikista kentistä..."
              single-line
              hide-details
              solo
              @keyup.enter="getDataFromApi()"
              @click:append-outer="getDataFromApi()"
            ></v-text-field>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :headers="headers"
        :items="reservations"
        :footer-props="globalValues.footerProps"
        :items-per-page="getDefaultTableRows()"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
        item-key="_id"
        show-expand
        @item-expanded="readMessage"
        :item-class="unreadMessage"
      >
        <template #expanded-item="{ item }">
          <td :colspan="headers.length">
            <div class="mt-2 mb-1">
              <h3 class="mb-1">Tilaus</h3>
              <p v-if="item.form.message">
                <strong>Asiakkaan tilausviesti:</strong>
                <span style="white-space: pre"> {{ item.form.message }}</span>
              </p>
              <p v-if="item.contract">
                <strong>Sopimusnumero:</strong> # {{ item.contract.contractNumber }}
              </p>
              <p>
                <strong>Maksun tila:</strong>
                {{ item.firstPayment == "paid" ? "Maksun veloitus onnistui" : "Odottaa maksua" }}
              </p>
              <p>
                <strong>Maksun tyyppi:</strong>
                {{ item.recurringPayments ? "Toistuvaisveloitus" : "Kertaveloitus" }}
              </p>
              <p>
                <strong>Summa:</strong>
                {{ formatCurrency(item.amount) }}{{ item.recurringPayments ? "/kk" : "" }}
              </p>

              <h3 class="mb-1">Tilaajan tiedot</h3>
              <p>{{ item.form.name }}</p>
              <p>{{ item.form.phone }}</p>
              <p>{{ item.form.email }}</p>

              <div v-if="item.tenant"></div>
            </div>
          </td>
        </template>

        <!-- Reservation number -->
        <template #[`item.reservationNumber`]="{ item }">
          <span class="font-weight-bold"># {{ item.reservationNumber }}</span>
        </template>

        <!-- Reservation number -->
        <template #[`item.tenant`]="{ item }">
          <router-link
            v-if="item.tenant"
            style="text-decoration: none"
            :to="`/tenant/overview/${item.tenant._id}`"
          >
            <span>{{ item.tenant.name }}</span>
          </router-link>

          <div v-else>{{ item.form.name }}</div>
        </template>

        <!-- Contract -->
        <template #[`item.contract`]="{ item }">
          <router-link
            v-if="item.contract"
            style="text-decoration: none"
            :class="getContractStateColor(item.contract.state)"
            :to="`/rental-contract/overview/${item.contract._id}`"
          >
            <span class="font-weight-bold">{{ item.contract.contractNumber }}</span>
          </router-link>

          <div v-else>Sopimusta ei ole luotu</div>
        </template>

        <!-- Startdate -->
        <template #[`item.startDate`]="{ item }">
          <span>{{ formatDate(item.lease.startDate) }}</span>
        </template>

        <!-- EndDate -->
        <template #[`item.endDate`]="{ item }">
          <span v-if="item.lease.endDate">{{ formatDate(item.lease.endDate) }}</span>
        </template>

        <!-- Apartment -->
        <template #[`item.apartment`]="{ item }">
          <router-link
            style="text-decoration: none"
            :to="`/apartment/overview/${item.apartment._id}`"
          >
            <span>{{ item.apartment.address }}</span>
            <span
              >{{ item.apartment.apartmentNumber ? `, ${item.apartment.apartmentNumber}` : "" }}
            </span>
          </router-link>
        </template>

        <!-- CreatedAt -->
        <template #[`item.createdAt`]="{ item }">
          <div>{{ formatDate(item.createdAt) }}</div>
        </template>

        <!-- CreatedAt -->
        <template #[`item.state`]="{ item }">
          <div class="font-weight-bold d-flex align-center text-no-wrap">
            <span :class="getReservationStateColor(item.state)">{{
              getReservationState(item.state)
            }}</span>
          </div>
        </template>

        <!-- Actions -->
        <!-- <template #[`item.actions`]="{ item }">
            <div class="action-grid-container">
              <v-icon title="Muokkaa" small @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon title="Poista" small @click="deleteItem(item)">mdi-delete</v-icon>
            </div> 
          </template>-->
        <template #no-data>
          <h2>Ei varauksia</h2>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";

export default {
  title: "Varaukset",

  mixins: [mixins],

  data: () => ({
    loading: true,
    options: {},
    dialog: false,
    search: "",
    headers: [
      { text: "Varaus", value: "reservationNumber" },
      { text: "Nimi", value: "tenant" },
      // {
      //   text: "Puhelin",
      //   value: "form.phone",
      // },
      // {
      //   text: "Email",
      //   value: "form.email",
      // },
      {
        text: "Vuokrakohde",
        value: "apartment",
        sortable: false,
      },
      {
        text: "Alkaa",
        value: "startDate",
      },
      {
        text: "Päättyy",
        value: "endDate",
      },
      {
        text: "Sopimus",
        value: "contract",
        sortable: false,
      },
      {
        text: "Tilauspäivä",
        value: "createdAt",
      },
      {
        text: "Tila",
        value: "state",
      },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    globalValues,
  }),

  computed: {
    ...mapState("reservation", ["reservations", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("reservation", ["getAllReservations", "deleteReservation", "markItemRead"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();
      let url = `/api/v1/reservation/get-reservations?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

      await this.getAllReservations(url);

      this.loading = false;
    },

    unreadMessage(item) {
      if (!item.read) {
        return "unread";
      }
    },

    readMessage(data) {
      if (!data.item.read) {
        setTimeout(() => this.markItemRead(data.item._id), 800);
      }
    },

    editItem(item) {
      this.$router.push("/reservation/edit/" + item._id);
    },

    // deleteItem(item) {
    //   if (confirm("Haluatko varmasti poistaa varauksen: " + item.name))
    //     this.deleteReservation(item._id);
    // },
  },
};
</script>

<style scoped>
.v-data-table >>> table .unread {
  font-weight: 600;
}
</style>
