var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3 min-w-0 w-full"},[_c('div',{staticClass:"display-1"},[_vm._v("Varaukset")]),_c('v-card',{staticClass:"mt-2"},[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Hae kaikista kentistä...","single-line":"","hide-details":"","solo":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getDataFromApi()},"click:append-outer":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col')],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reservations,"footer-props":_vm.globalValues.footerProps,"items-per-page":_vm.getDefaultTableRows(),"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalLength,"item-key":"_id","show-expand":"","item-class":_vm.unreadMessage},on:{"update:options":function($event){_vm.options=$event},"item-expanded":_vm.readMessage},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('div',{staticClass:"mt-2 mb-1"},[_c('h3',{staticClass:"mb-1"},[_vm._v("Tilaus")]),(item.form.message)?_c('p',[_c('strong',[_vm._v("Asiakkaan tilausviesti:")]),_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(" "+_vm._s(item.form.message))])]):_vm._e(),(item.contract)?_c('p',[_c('strong',[_vm._v("Sopimusnumero:")]),_vm._v(" # "+_vm._s(item.contract.contractNumber)+" ")]):_vm._e(),_c('p',[_c('strong',[_vm._v("Maksun tila:")]),_vm._v(" "+_vm._s(item.firstPayment == "paid" ? "Maksun veloitus onnistui" : "Odottaa maksua")+" ")]),_c('p',[_c('strong',[_vm._v("Maksun tyyppi:")]),_vm._v(" "+_vm._s(item.recurringPayments ? "Toistuvaisveloitus" : "Kertaveloitus")+" ")]),_c('p',[_c('strong',[_vm._v("Summa:")]),_vm._v(" "+_vm._s(_vm.formatCurrency(item.amount))+_vm._s(item.recurringPayments ? "/kk" : "")+" ")]),_c('h3',{staticClass:"mb-1"},[_vm._v("Tilaajan tiedot")]),_c('p',[_vm._v(_vm._s(item.form.name))]),_c('p',[_vm._v(_vm._s(item.form.phone))]),_c('p',[_vm._v(_vm._s(item.form.email))]),(item.tenant)?_c('div'):_vm._e()])])]}},{key:"item.reservationNumber",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v("# "+_vm._s(item.reservationNumber))])]}},{key:"item.tenant",fn:function(ref){
var item = ref.item;
return [(item.tenant)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/tenant/overview/" + (item.tenant._id))}},[_c('span',[_vm._v(_vm._s(item.tenant.name))])]):_c('div',[_vm._v(_vm._s(item.form.name))])]}},{key:"item.contract",fn:function(ref){
var item = ref.item;
return [(item.contract)?_c('router-link',{class:_vm.getContractStateColor(item.contract.state),staticStyle:{"text-decoration":"none"},attrs:{"to":("/rental-contract/overview/" + (item.contract._id))}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.contract.contractNumber))])]):_c('div',[_vm._v("Sopimusta ei ole luotu")])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.lease.startDate)))])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [(item.lease.endDate)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.lease.endDate)))]):_vm._e()]}},{key:"item.apartment",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":("/apartment/overview/" + (item.apartment._id))}},[_c('span',[_vm._v(_vm._s(item.apartment.address))]),_c('span',[_vm._v(_vm._s(item.apartment.apartmentNumber ? (", " + (item.apartment.apartmentNumber)) : "")+" ")])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold d-flex align-center text-no-wrap"},[_c('span',{class:_vm.getReservationStateColor(item.state)},[_vm._v(_vm._s(_vm.getReservationState(item.state)))])])]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei varauksia")])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }